module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@5.13.4_rea_jiompyucyhql72bixm2wb7m7h4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.4/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/app/src/components/Layout/LayoutWrapper.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.4_i18next@21.10.0_react-i18next@11.18.6_react@18.2.0/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","fr"],"defaultLanguage":"de","siteUrl":"https://amenti.ch/","redirect":true,"generateDefaultLanguagePage":true,"i18nextOptions":{"defaultNS":"common","ns":"common","debug":false,"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_gatsby@5.13.4_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-137738616-2","cookieName":"amenti-google-analytics"},"googleTagManager":{"trackingId":"GTM-5VDRF8X","cookieName":"amenti-google-tagmanager"},"facebookPixel":{"pixelId":"861004149072477","cookieName":"amenti-facebook-pixel"},"hotjar":{"hjid":1373183,"hjsv":6},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.4.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
